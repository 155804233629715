<template>
  <div>
    <!-- Row Start -->
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Virtual Address</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="outline-primary" v-b-modal.add-va-modal>Add Virtual Address</b-button>
            </div>
          </div>

          <div class="card-body body-fluid">
            <div class="table-responsive">
              <b-table responsive class="text-nowrap" small striped hover :items="addresses" :fields="fields">
                <template #cell(actions)="row">
                  <b-button size="sm" @click="ShowEditModal(row.item.id)" variant="primary">
                    <i class="flaticon2-edit icon-sm"></i></b-button>
                  <b-button @click="deletecountry(row.item.id)" class="ml-2" size="sm" variant="danger">
                    <i class="flaticon2-trash icon-sm"></i></b-button>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Row End -->
    <!-- Add Modal -->
    <b-modal id="add-va-modal" ref="modal" size="lg" @show="resetModal" @hidden="resetModal" @ok="addaddress"
      title="Add Virtual Address">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="Branch">
              <b-form-select v-model="form.branch_id" :options="branches" value-field="id" @change="updateCountry"
                text-field="name"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <b-form-group label="Name" label-for="name-input">
              <b-form-input id="name-input" v-model="form.name" placeholder="Name" required></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Phone" label-for="name-input">
              <b-form-input id="name-input" v-model="form.phone" placeholder="Phone" required></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group label="Address line 1">
              <b-form-textarea v-model="form.address_line_1" placeholder="Address line 1" rows="3"></b-form-textarea>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="City / State">
              <b-form-input id="name-input" v-model="form.city_state"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Country">
              <b-form-input id="name-input" v-model="form.country" disabled="true"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Zip">
              <b-form-input id="name-input" v-model="form.zip"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group label="Remarks">
              <b-form-textarea v-model="form.remarks" placeholder="Enter remarks" rows="3"></b-form-textarea>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal id="edit-va-modal" ref="modal" size="lg" @hidden="resetModal" @ok="updateaddress"
      title="Edit Virtual Address">
      <form ref="editform" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="Branch">
              <b-form-select v-model="form.branch_id" :options="branches" value-field="id"
                text-field="name"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <b-form-group label="Name" label-for="name-input">
              <b-form-input id="name-input" v-model="form.name" placeholder="Name" required></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Phone" label-for="name-input">
              <b-form-input id="name-input" v-model="form.phone" placeholder="Phone" required></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group label="Address line 1">
              <b-form-textarea v-model="form.address_line_1" placeholder="Address line 1" rows="3"></b-form-textarea>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="City / State">
              <b-form-input id="name-input" v-model="form.city_state"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Country">
              <b-form-input id="name-input" v-model="form.country"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Zip">
              <b-form-input id="name-input" v-model="form.zip"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group label="Remarks">
              <b-form-textarea v-model="form.remarks" placeholder="Enter remarks" rows="3"></b-form-textarea>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <loading :active.sync="isLoading"></loading>
  </div>
</template>
<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {

      addresses: [],
      isLoading: false,
      branches: [],
      countries: [],
      fields: [
        "branch",
        "name",
        "phone",
        "address_line_1",
        "city_state",
        "country",
        "zip",
        "remarks",
        "actions",
      ],
      form: {
        branch_id: null,
        name: null,
        phone: null,
        address_line_1: null,
        address_line_2: null,
        city_state: null,
        country: null,
        country_id: null,
        zip: null,
        remarks: null,
        id: 0,
      },
      submittedNames: [],
    };
  },
  created() {
    this.getAllVA();
    this.getAllBranches();
  },
  components: {
    Loading,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Settings" },
      { title: "Virtual Address" },
    ]);
  },
  methods: {
    checkErrors() {
      if (
        this.form.branch_id &&
        this.form.name &&
        this.form.phone &&
        this.form.address_line_1 &&
        this.form.city_state &&
        this.form.country &&
        this.form.zip
      ) {
        return true;
      }
      this.errors = [];

      if (!this.form.branch_id) {
        this.errors.push("Branch is required.");
      }
      if (!this.form.name) {
        this.errors.push("Name is required.");
      }
      if (!this.form.phone) {
        this.errors.push("Phone is required.");
      }
      if (!this.form.address_line_1) {
        this.errors.push("Address line 1 is required.");
      }
      if (!this.form.city_state) {
        this.errors.push("City/State is required.");
      }
      if (!this.form.country) {
        this.errors.push("Country is required.");
      }
      if (!this.form.zip) {
        this.errors.push("Zip is required.");
      }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
    },
    resetModal() {
      this.form.name = null;
      this.form.phone = null;
      this.form.branch_id = null;
      this.form.address_line_1 = null;
      this.form.address_line_2 = null;
      this.form.city_state = null;
      this.form.country = null;
      this.form.zip = null;
      this.form.remarks = null;
    },
    makeToastVariant(variant = null, text, title) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    addaddress(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Exit when the form isn't valid
      // if (!this.checkFormValidity()) {
      //   return;
      // }
      if (this.checkErrors() == true) {
        this.isLoading = true;
        ApiService.post("virtualaddress", this.form)
          .then(() => {

            this.getAllVA();
            this.$nextTick(() => {
              this.$bvModal.hide("add-va-modal");
              this.makeToastVariant(
                "success",
                "Virtual Address added successfully",
                "Success"
              );
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    updateaddress(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.checkErrors() == true) {
        this.isLoading = true;
        ApiService.update("virtualaddress", this.form.id, this.form)
          .then(() => {
            this.getAllVA();
            this.$nextTick(() => {
              this.makeToastVariant(
                "info",
                "Virtual Address updated successfully",
                "Success"
              );
              this.$bvModal.hide("edit-va-modal");
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    getAllVA() {
      this.isLoading = true;
      ApiService.get("virtualaddress")
        .then(({ data }) => {
          this.addresses = data;
          this.isLoading = false;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    ShowEditModal(id) {
      this.isLoading = true;
      ApiService.get("virtualaddress", id)
        .then(({ data }) => {
          this.form = data;
          this.isLoading = false;
          this.$bvModal.show("edit-va-modal");
        })
        .catch(() => {
        });
    },
    deletecountry(id) {
      this.isLoading = true;
      ApiService.delete("virtualaddress", id)
        .then(() => {
          this.makeToastVariant(
            "warning",
            "Virtual Address deleted successfully",
            "Success"
          );
          this.getAllVA();
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    getAllBranches() {
      ApiService.get("branch")
        .then(({ data }) => {
          this.branches = data;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    getCountriesList() {
      ApiService.get('getcountries').then(({ data }) => {
        this.countries = data;
      })
        .catch(() => {
          console.log(data);
        });
    },
    getCountryById(id) {
      ApiService.get('getCountryById', id).then(({ data }) => {

        this.form.country = data[0].name;
        this.form.country_id = data[0].id;
      })
        .catch(() => {
          console.log(data);
        });
    },
    updateCountry() {
      var branch = this.branches.filter(x => x.id == this.form.branch_id)[0];
      this.getCountryById(branch.country_id);
    }
  },
};
</script>
